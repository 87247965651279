export const UPDATE_PRIVACYPOLICY = "auth/UPATE_PRIVACYPOLICY";
export const UPDATE_PRIVACYPOLICY_FAILED = "auth/UPDATE_PRIVACYPOLICY_FAILED";
export const UPDATE_PRIVACYPOLICY_COMPLETED = "auth/UPDATE_PRIVACYPOLICY_COMPLETED";

export const UPDATE_PROFILEDETAILS = 'auth/UPDATE_PROFILEDETAILS';
export const UPDATE_PROFILEDETAILS_FAILED = "auth/UPDATE_PROFILEDETAILS_FAILED";
export const UPDATE_PROFILEDETAILS_COMPLETED = "auth/UPDATE_PROFILEDETAILS_COMPLETED";

export const GET_PROFILEDETAILS = 'auth/GET_PROFILEDETAILS';
export const GET_PROFILEDETAILS_FAILED = "auth/GET_PROFILEDETAILS_FAILED";
export const GET_PROFILEDETAILS_COMPLETED = "auth/GET_PROFILEDETAILS_COMPLETED";

export const CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_FAILED = "auth/CHANGE_PASSWORD_FAILED";
export const CHANGE_PASSWORD_COMPLETED = "auth/CHANGE_PASSWORD_COMPLETED";