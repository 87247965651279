import { get } from "lodash";
import { combineReducers } from "redux";
import { createReducer } from "../../utils";
import * as types from './types';

const getEventList = createReducer(false)({
    [types.GET_EVENT_LIST_COMPLETED]: (state, action) => {
        return get(action, 'payload.payload.getEventList', [])
    }
})

const getEventDetails = createReducer(false)({
    [types.GET_EVENT_DETAILS_COMPLETED]: (state, action) => {
        return get(action, 'payload', [])
    }
})

const updateEvent = createReducer(false)({
    [types.EVENT_STATUS_UPDATE_COMPLETED]: (state, action) => {
        return get(action, 'payload', [])
    }
})

const addEvent = createReducer(false)({
    [types.ADD_EVENT_COMPLETED]: (state, action) => {
        return get(action, 'payload', [])
    }
})

const eventStatusUpdate = createReducer(false)({
    [types.EVENT_STATUS_UPDATE_COMPLETED]: (state, action) => {
        return get(action, 'payload', [])
    }
})

const deleteEvent = createReducer(false)({
    [types.DELETE_EVENT_COMPLETED]: (state, action) => {
        return get(action, 'payload', [])
    }
})


export default combineReducers({
    eventList: getEventList,
    getEventDetails: getEventDetails,
    updateEvent: updateEvent,
    addEvent: addEvent,
    eventStatusUpdate: eventStatusUpdate,
    deleteEvent: deleteEvent,
})