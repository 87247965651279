import * as types from './types';

export const updatePrivacyPolicy = (payload) =>
({
    type: types.UPDATE_PRIVACYPOLICY_COMPLETED,
    meta: {
        async: true,
        blocking: false,
        path: `auth/updateProfile`,
        method: 'PUT',
        body: payload
    }
})

export const updateProfileDetails = (payload) => (
    {
        type: types.UPDATE_PROFILEDETAILS_COMPLETED,
        meta: {
            async: true,
            blocking: false,
            path: 'auth/updateProfile',
            method: 'PUT',
            body: payload
        }
    })

export const getProfileDetails = () => ({
    type: types.GET_PROFILEDETAILS_COMPLETED,
    meta: {
        async: true,
        blocking: false,
        path: 'auth/get',
        method: 'GET'
    }
})

export const changePassword = (payload) => ({
    type: types.CHANGE_PASSWORD_COMPLETED,
    meta: {
        async: true,
        blocking: false,
        path: `auth/changePassword`,
        method: 'PUT',
        body: payload
    }
})