import { updatePrivacyPolicy, updateProfileDetails as updateProfileAction, getProfileDetails, changePassword as changePasswordAction } from './action';

const updateProfileDetails = (payload) => {
    return async (dispatch) => {
        let response = await dispatch(updateProfileAction(payload));
        return response
    };
}

const changePassword = (payload) => {
    return async (dispatch) => {
        let response = await dispatch(changePasswordAction(payload));
        return response
    };
}

export { updatePrivacyPolicy, updateProfileDetails, getProfileDetails, changePassword };