// GET EVENT DETAILS
export const GET_EVENT_LIST = "event/GET_EVENT_LIST";
export const GET_EVENT_LIST_FAILED = "event/GET_EVENT_LIST_FAILED";
export const GET_EVENT_LIST_COMPLETED = "event/GET_EVENT_LIST_COMPLETED";


export const GET_EVENT_DETAILS = "event/GET_EVENT_DETAILS";
export const GET_EVENT_DETAILS_FAILED = "event/GET_EVENT_DETAILS_FAILED";
export const GET_EVENT_DETAILS_COMPLETED = "event/GET_EVENT_COMPLETED"

// localhost:4000/v1/event/update
export const EVENT_UPDATE = "event/EVENT_UPDATE";
export const EVENT_UPDATE_FAILED = "event/EVENT_UPDATE_FAILED";
export const EVENT_UPDATED_COMPLETED = "event/EVENT_UPDATE_COMPLETED";


//EVENT STATUS UPDATE
export const EVENT_STATUS_UPDATE = "event/STATUS_UPDATE";
export const EVENT_STATUS_UPDATE_FAILED = "event/STATUS_UPDATE_FAILED";
export const EVENT_STATUS_UPDATE_COMPLETED = "event/STATUS_UPDATE_COMPLETED";


//DELETE EVENT
export const DELETE_EVENT = "event/DELETE";
export const DELETE_EVENT_FAILED = "event/DELETE_FAILED";
export const DELETE_EVENT_COMPLETED = "event/DELETE_COMPLETED";

// /event/add


// post = localhost:4000/v1/event/add
//ADD EVENT
export const ADD_EVENT = "event/ADD";
export const ADD_EVENT_FAILED = "event/ADD_FAILED";
export const ADD_EVENT_COMPLETED = "event/ADD_COMPLETED";



