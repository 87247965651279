//get Business List

export const GET_BUSINESS_LIST = "business/GET_BUSINESS_LIST";
export const GET_BUSINESS_LIST_FAILED = "business/GET_BUSINESS_LIST_FAILED";
export const GET_BUSINESS_LIST_COMPLETED = "business/GET_BUSINESS_LIST_COMPLETED";

export const GET_ALL_BUSINESS_LIST = "business/GET_ALL_BUSINESS_LIST";
export const GET_ALL_BUSINESS_LIST_FAILED = "business/GET_ALL_BUSINESS_LIST_FAILED";
export const GET_ALL_BUSINESS_LIST_COMPLETED = "business/GET_ALL_BUSINESS_LIST_COMPLETED";



//business Update
export const BUSINESS_STATUS_UPDATE = "/business/BUSINESS_STATUS_UPDATE";
export const BUSINESS_STATUS_UPDATE_FAILED = "/business/BUSINESS_STATUS_UPDATE_FAILED";
export const BUSINESS_STATUS_UPDATE_COMPLETED = "/business/BUSINESS_STATUS_UPDATE_COMPLETED";

//delete BusinesS

export const DELETE_BUSINESS_LIST = "business/DELETE";
export const DELETE_BUSINESS_FAILED = "business/DELETE_FAILED";
export const DELETE_BUSINESS_COMPLETED = "business/DELETE_COMPLETED";

// Update SideMenu

export const UPDATE_SIDEMENU = "UPDATE_SIDEMENU";


//update Business List

export const UPDATE_BUSINESS_LIST = "UPDATE_BUSINESS_LIST"


// update Business
export const UPDATE_BUSINESS = "business/UPDATE_BUSINESS";
export const UPDATE_BUSINESS_FAILED = "business/UPDATE_BUSINESS_FAILED";
export const UPDATE_BUSINESS_COMPLETED = "business/UPDATE_BUSINESS_COMPLETED";


// add Business

export const ADD_BUSINESS = "business/ADD";
export const ADD_BUSINESS_FAILED = "business/ADD_BUSINESS_FAILED";
export const ADD_BUSINESS_COMPLTED = "business/ADD_BUSINESS_COMPLETED";


//get service Details

export const GET_BUSINESS_ITEM_DETAILS = "business/GET_BUSINESS_ITEM_DETAILS";
export const GET_BUSINESS_ITEM_FAILED = "business/GET_BUSINESS_ITEM_FAILED";
export const GET_BUSINESS_ITEM_COMPLETED = "business/GET_BUSINESS_ITEM_COMPLETED";





